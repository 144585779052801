import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-cursor",
  templateUrl: "./cursor.component.html",
  styleUrls: ["./cursor.component.scss"],
})
export class CursorComponent implements OnInit {
  private cursor: HTMLElement;
  private clicking = false;
  private lastKnownY = 0;

  constructor() {}

  ngOnInit(): void {
    if (!this.mobileOS()) {
      const updateProperties = (elem, state) => {
        elem.style.setProperty("--x", `${state.x}px`);
        elem.style.setProperty("--y", `${state.y}px`);
      };

      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("click", this.handleClick);
    }
  }

  ngAfterViewInit(): void {
    if (!this.mobileOS()) {
      this.cursor = document.querySelector(".cursor");
    }
  }

  handleMouseMove(e) {
    if (!this.cursor) this.cursor = document.querySelector(".cursor");
    this.cursor.style.setProperty("--x", `${e.clientX}px`);
    this.cursor.style.setProperty("--y", `${e.clientY}px`);
    this.cursor.style.setProperty("--moved", '1');
    this.lastKnownY = e.clientY;
  }

  handleScroll(e) {
    this.cursor.style.setProperty("--y", `${this.lastKnownY + scrollY}px`);
  }

  handleClick(e) {
    if (!this.cursor) this.cursor = document.querySelector(".cursor");
    this.cursor.classList.add("click");
    this.clicking = true;
    const ripple = document.createElement("div");
    const rippleId = Math.ceil(Math.random() * 100);
    ripple.classList.add("ripple");
    ripple.classList.add(`ripple--${rippleId}`);
    // const r = Math.floor(Math.random() * 255)
    // const g = Math.floor(Math.random() * 255)
    // const b = Math.floor(Math.random() * 255)
    // ripple.style.backgroundColor = `rgb(${r}, ${g}, ${b})`
    ripple.style.backgroundColor = `rgb(255,255,255)`;
    this.cursor.appendChild(ripple);

    setTimeout(() => {
      this.cursor.classList.remove("click");
      this.clicking = false;
      document.querySelector(`.ripple--${rippleId}`).remove();
    }, 1000);
    // }
  }

  mobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) return "windows_phone";
    if (/android/i.test(userAgent)) return "android";
    if (/iPad|iPhone|iPod/.test(userAgent)) return "ios";
    return null;
  }
}
