<div id="portfolio" class="portfolio">
  <a href="#portfolio"></a>
  <div class="portfolio__wrapper">
    <h1 class="section-title">02. portfolio</h1>
    <p class="portfolio__intro">
      some of the projects and websites i have had the opportunity to be part of
      as a developer over the years
    </p>
    <div class="portfolio__projects">
      <div
        class="project"
        *ngFor="let project of projects"
        [class]="
          !project.id && !project.id && project.id !== 0 ? 'coming-soon' : ''
        "
      >
        <ng-container *ngIf="project.id || project.id === 0">
          <a href="{{ project.url }}" target="_blank">{{ project.title }}</a>
          <img src="../../assets/{{ project.img }}" alt="" />
        </ng-container>
        <ng-container *ngIf="!project.id && project.id !== 0">
          <p>coming soon</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
