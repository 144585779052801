import { Component } from '@angular/core';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyDohnOdBWOkUUb8wXTbowDkurtcgFralpU",
//   authDomain: "hugomiranda-ae3a4.firebaseapp.com",
//   projectId: "hugomiranda-ae3a4",
//   storageBucket: "hugomiranda-ae3a4.appspot.com",
//   messagingSenderId: "652570067033",
//   appId: "1:652570067033:web:c2dc588525182233707148",
//   measurementId: "G-PBW4XG0LMP"
// };
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'hugomiranda';

  constructor () {
  // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  }
}
