<div class="nav">
  <div
    (click)="toggleMenu()"
    class="menu-toggle"
    [ngClass]="{ 'menu-toggle--display': display }"
  >
    <img src="../../assets/close.svg" />
  </div>
  <div class="top-nav" [ngClass]="{ 'top-nav--display': display }">
    <div (click)="openCV()" class="top-nav__item top-nav__item--cv">cv</div>
    <a href="mailto:i@hugomiranda.dev" class="top-nav__item">
      <img src="/assets/social/email.svg" />
    </a>
    <a
      href="http://www.linkedin.com/in/hugomiranda94"
      target="_blank"
      class="top-nav__item"
    >
      <img src="/assets/social/linkedin.svg" />
    </a>
    <a
      href="http://www.github.com/hugomiranda94"
      target="_blank"
      class="top-nav__item"
    >
      <img src="/assets/social/github.svg" />
    </a>
  </div>
</div>
