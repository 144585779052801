<div class="contact">
  <div class="contact__wrapper">
    <h1 class="section-title">03. contact</h1>
    <a class="contact__email" href="mailto:i@hugomiranda.dev">
      <div class="hover-container">
        <p class="hover--hide">let's talk</p>
        <p class="hover--show">i@hugomiranda.dev</p>
      </div>
    </a>
    <div class="contact__social">
      <div (click)="openCV()" class="contact__item contact__item--cv">cv</div>
      <a
        href="http://www.linkedin.com/in/hugomiranda94"
        target="_blank"
        class="contact__item"
      >
        <img src="/assets/social/linkedin.svg" />
      </a>
      <a
        href="http://www.github.com/hugomiranda94"
        target="_blank"
        class="contact__item"
      >
        <img src="/assets/social/github.svg" />
      </a>
    </div>
  </div>
  <div class="contact__blur">
    <svg
      class="blob blob--1"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      fill="none"
      viewBox="0 0 64 43"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.dev/svgjs"
    >
      <path
        fill='url("#SvgjsLinearGradient1898")'
        d="M49.1 7.5c-.5 0-.9 0-1.4.1C43.9 3.4 38.5.8 32.4.8c-6.1 0-11.6 2.7-15.4 7-.8-.1-1.7-.2-2.5-.2-7.7 0-14 6.3-14 14s6.2 14 14 14c.9 0 1.7-.1 2.5-.2 3.8 4.3 9.3 7 15.4 7s11.5-2.6 15.3-6.8c.5 0 .9.1 1.4.1 7.7 0 14-6.3 14-14 0-7.9-6.3-14.2-14-14.2Z"
      ></path>
      <defs>
        <linearGradient
          gradientTransform="rotate(0 0.5 0.5)"
          id="SvgjsLinearGradient1898"
        >
          <stop
            stop-opacity=" 1"
            stop-color="rgba(187, 110, 252)"
            offset="0"
          ></stop>
          <stop
            stop-opacity=" 1"
            stop-color="rgba(120, 9, 255)"
            offset="0.008515625"
          ></stop>
          <stop
            stop-opacity=" 1"
            stop-color="rgba(194, 78, 7)"
            offset="0.723515625"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
    <svg
      class="blob blob--2"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      fill="none"
      viewBox="0 0 64 43"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.dev/svgjs"
    >
      <path
        fill='url("#SvgjsLinearGradient1822")'
        d="M49.1 7.5c-.5 0-.9 0-1.4.1C43.9 3.4 38.5.8 32.4.8c-6.1 0-11.6 2.7-15.4 7-.8-.1-1.7-.2-2.5-.2-7.7 0-14 6.3-14 14s6.2 14 14 14c.9 0 1.7-.1 2.5-.2 3.8 4.3 9.3 7 15.4 7s11.5-2.6 15.3-6.8c.5 0 .9.1 1.4.1 7.7 0 14-6.3 14-14 0-7.9-6.3-14.2-14-14.2Z"
      ></path>
      <defs>
        <linearGradient
          gradientTransform="rotate(0 0.5 0.5)"
          id="SvgjsLinearGradient1822"
        >
          <stop
            stop-opacity=" 1"
            stop-color="rgba(0, 255, 201)"
            offset="0"
          ></stop>
          <stop
            stop-opacity=" 1"
            stop-color="rgba(2, 0, 255)"
            offset="1"
          ></stop>
          <stop
            stop-opacity=" 1"
            stop-color="rgba(84, 134, 241)"
            offset="1"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
    <svg
      class="blob blob--3"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      fill="none"
      viewBox="0 0 64 43"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.dev/svgjs"
    >
      <path
        fill='url("#SvgjsLinearGradient1320")'
        d="M49.1 7.5c-.5 0-.9 0-1.4.1C43.9 3.4 38.5.8 32.4.8c-6.1 0-11.6 2.7-15.4 7-.8-.1-1.7-.2-2.5-.2-7.7 0-14 6.3-14 14s6.2 14 14 14c.9 0 1.7-.1 2.5-.2 3.8 4.3 9.3 7 15.4 7s11.5-2.6 15.3-6.8c.5 0 .9.1 1.4.1 7.7 0 14-6.3 14-14 0-7.9-6.3-14.2-14-14.2Z"
      ></path>
      <defs>
        <linearGradient
          gradientTransform="rotate(0 0.5 0.5)"
          id="SvgjsLinearGradient1320"
        >
          <stop
            stop-opacity=" 1"
            stop-color="rgba(243, 194, 22)"
            offset="0"
          ></stop>
          <stop
            stop-opacity=" 1"
            stop-color="rgba(83, 161, 102)"
            offset="0.998515625"
          ></stop>
          <stop
            stop-opacity=" 1"
            stop-color="rgba(234, 204, 248)"
            offset="1"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="contact__mask"></div>
</div>
