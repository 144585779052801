<div class="resume">
  <div class="resume__content content">
    <h1 class="section-title">01. resume</h1>
    <div class="details--wrapper">
      <div class="content__details content__details--work">
        <div class="content__item content__item--title">experience</div>
        <div class="content__item">
          <a href="https://getfishtank.ca/" target="_blank">
            fishtank (2024 - now)
          </a>
          <p>front end developer</p>
        </div>
        <div class="content__item">
          <a href="https://signifly.com/" target="_blank">
            signifly (2021 - 2024)
          </a>
          <p>front end developer</p>
        </div>
        <div class="content__item">
          <a href="https://www.accenture.com/" target="_blank">
            accenture (2018 - 2021)
          </a>
          <p>front end developer</p>
        </div>
        <div class="content__item">
          <a href="https://www.epicor.com/" target="_blank">
            epicor (2017 - 2018)
          </a>
          <p>software developer & q.a. analyst internship</p>
        </div>
      </div>

      <div class="content__details content__details--education">
        <div class="content__item content__item--title">education</div>
        <div class="content__item">
          <a href="https://www.uanl.mx/en/" target="_blank">
            universidad autónoma de nuevo león (2019)
          </a>
          <p>bachelor's degree in software engineering</p>
        </div>
      </div>

      <div class="content__details content__details--more">
        <div class="content__item">
          <p>languages: spanish, english & french</p>
        </div>
      </div>

      <div class="content__details content__details--skills">
        <div class="content__item content__item--title">
          <p>main skills</p>
        </div>
        <div class="content__item">
          <p>javascript, vue, angular, react, html, css, shopify, scrum, gsap</p>
        </div>
        <!-- <div class="content__item content__item--btn">
          <button (click)="seeSkills()" class="main-btn">see all</button>
        </div> -->
      </div>

      <div class="content__details content__details--more">
        <div class="content__item">
          <p>location: calgary, ab, canada</p>
        </div>
      </div>
    </div>
  </div>
  <div class="resume__picture-container">
    <img
      class="resume__picture-container--img"
      src="../../assets/hm-mtl.webp"
      alt=""
      loading=lazy
    />
    <div class="resume__picture-container--wrapper"></div>
  </div>
</div>
