import { AfterContentInit, Component, OnInit } from "@angular/core";
import { gsap } from "gsap";
import * as THREE from "three";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit, AfterContentInit {
  constructor() {}

  ngOnInit(): void {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    const light = new THREE.PointLight(0xffff00, 1000, 10);
    light.position.set(5, 5, 5);
    scene.add(light);

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.querySelector(".hero__3d").appendChild(renderer.domElement);

    window.addEventListener("resize", () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    });

    const geometry = new THREE.OctahedronGeometry(1, 2);
    const material = new THREE.MeshDepthMaterial({
      wireframe: true,
    });

    const item1 = new THREE.Mesh(geometry, material);
    const item2 = new THREE.Mesh(geometry, material);
    const item3 = new THREE.Mesh(geometry, material);
    const item4 = new THREE.Mesh(geometry, material);
    const item5 = new THREE.Mesh(geometry, material);
    scene.add(item1, item2, item3, item4, item5);

    item5.position.z = -4;
    item4.position.z = -2;
    item3.position.z = -1.5;
    item2.position.z = -0.5;
    item1.position.z = 0;

    let zooming = false;
    let movingCamera = false;
    let movingItem = true;
    let speed = 1 / 500;

    if (!this.mobileOS()) {

      document.addEventListener("mousemove", (e) => {
        item1.rotation.x += e.movementX / 750;
        item1.rotation.y += e.movementY / 750;

        item2.rotation.x -= e.movementX / 1000;
        item2.rotation.y -= e.movementY / 1000;

        item3.rotation.x += e.movementX / 1000;
        item3.rotation.y += e.movementY / 1000;

        item4.rotation.x -= e.movementX / 500;
        item4.rotation.y -= e.movementY / 500;

        item5.rotation.x += e.movementX / 250;
        item5.rotation.y += e.movementY / 250;
      });
    }

    let currentScroll = 0;

    document.addEventListener("scroll", (e) => {
      camera.position.z = currentScroll / (500 * -1);
      camera.position.x = currentScroll / (2000 * -1);
      camera.position.y = currentScroll / (1500 * -1);
      currentScroll = window.scrollY <= window.innerHeight * 0.75 ? window.scrollY : window.innerHeight;
    });

    function animate() {
      try {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);

        if (movingItem) {
          item1.rotation.x += speed;
          item1.rotation.y += speed;
          item2.rotation.x -= speed * 2;
          item2.rotation.y -= speed * 2;
          item3.rotation.x += speed / 3;
          item3.rotation.y += speed / 3;
          item4.rotation.x += speed * 3;
          item4.rotation.y += speed * 3;
          item5.rotation.x += speed * 4;
          item5.rotation.y += speed * 4;
        }

        if (zooming && movingCamera) {
          camera.position.z -= 0.01;
        } else if (movingCamera) {
          camera.position.z += 0.01;
        }

        if (camera.position.z === 2) {
          zooming = true;
        } else if (camera.position.z === 1) {
          zooming = false;
        }
      } catch (e) {
        console.log("e", e);
      }
    }

    animate();

    // gsap.to(".hero__wrapper", {
    //   backgroundColor: "transparent",
    //   duration: 0.5,
    //   delay: 1,
    // });

    // let tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".hero",
    //     pin: true,
    //     start: "top top",
    //     end: "+=1000",
    //     scrub: 1
    //   }
    // });

    // tl.to(item.rotation, { y: Math.PI * 2, x: Math.PI * 2 })
    // tl.to(item.position, { y: -0.2, x: 0.5 }, '<')
    // tl.to(camera.position, { z: 1.5 }, '<')
  }

  ngAfterContentInit(): void {
    // const tl = gsap.timeline({})
    // tl.fromTo(".hero__3d", { opacity: 0 }, { opacity: 0.05 }, '<1');
    // tl.fromTo(".hero__title", { opacity: 0 }, { opacity: 1 }, '<2');
    // tl.fromTo(".hero__subtitle", { opacity: 0 }, { opacity: 1 }, '<3');
  }

  mobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) return "windows_phone";
    if (/android/i.test(userAgent)) return "android";
    if (/iPad|iPhone|iPod/.test(userAgent)) return "ios";
    return null;
  }
}
