import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: "app-main-container",
  templateUrl: "./main-container.component.html",
  styleUrls: ["./main-container.component.scss"],
})
export class MainContainerComponent
  implements OnInit, AfterViewInit, AfterContentInit
{
  constructor() {}

  ngOnInit(): void {
    gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterContentInit(): void {
    gsap.fromTo(
      ".hero__3d",
      { opacity: 0 },
      { opacity: 0.05, delay: 0.5, duration: 1 }
    );
    gsap.fromTo(
      ".hero__wrapper",
      { opacity: 0 },
      { opacity: 1, delay: 1, duration: 1 }
    );
    gsap.fromTo(
      ".cursor",
      { opacity: 0 },
      { opacity: 1, delay: 1, duration: 1 }
    );
    gsap.fromTo(
      ".menu-toggle",
      { opacity: 0 },
      { opacity: 1, delay: 1, duration: 1 }
    );
  }

  ngAfterViewInit(): void {
    this.initGSAP();
  }

  initGSAP() {
    const tlScroll = gsap.timeline({
      scrollTrigger: {
        trigger: ".main-container",
        pin: true,
        start: "top top",
        end: "+=500",
        scrub: true,
        snap: {
          snapTo: "labels",
          duration: { min: 0.2, max: 0.5 },
          delay: 0.1,
          ease: "power1.inOut",
        },
      },
    });

    tlScroll.addLabel("#start");
    tlScroll.fromTo(
      ".hero__title",
      { opacity: 1, xPercent: 0 },
      { opacity: 0, xPercent: -100 },
      "<"
    );
    tlScroll.fromTo(
      ".hero__subtitle",
      { opacity: 1, xPercent: 0 },
      { opacity: 0, xPercent: -300 },
      "<"
    );
    tlScroll.fromTo(".resume", { x: "105%" }, { x: "0" }, "<0.1");

    console.log("this.mobileOS()", this.mobileOS());
    if (!this.mobileOS()) {
      tlScroll.fromTo(
        ".resume__content",
        { x: "-50%", opacity: 0 },
        { x: "0", opacity: 1 },
        "<"
      );

      tlScroll.fromTo(
        ".resume__picture-container",
        { y: "50%", x: "10%", opacity: 0 },
        { y: "10%", x: "10%", opacity: 1 },
        "<0.1"
      );
    }

    tlScroll.addLabel("#resume");
    tlScroll.to(".scroll", { opacity: "0" }, "<");
    tlScroll.addLabel("#end");
  }

  mobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor;
    console.log("userAgent", userAgent);
    if (/windows phone/i.test(userAgent)) return "windows_phone";
    if (/android/i.test(userAgent)) return "android";
    if (/iPad|iPhone|iPod/.test(userAgent)) return "ios";
    return null;
  }
}
