import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.scss"],
})
export class ResumeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  seeSkills(): void {
    window.open("https://www.linkedin.com/in/hugomiranda94/details/skills/", "_blank")
  }
}
