import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openCV() {
    window.open("assets/HUGO MIRANDA - CV.pdf", "_blank", "fullscreen=yes");
    return false;
  }

}
