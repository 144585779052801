<div class="hero">
  <div class="hero__wrapper">
    <h1 class="hero__title hero__title--1 hero__title--desktop">
      <span>h</span>
      <span>u</span>
      <span>g</span>
      <span>o</span>
      <span class="dot">&#183;</span>
      <span>m</span>
      <span>i</span>
      <span>r</span>
      <span>a</span>
      <span>n</span>
      <span>d</span>
      <span>a</span>
    </h1>
    <h1 class="hero__title hero__title--1 hero__title--mobile">
      hugo miranda
    </h1>
    <div class="hero__subtitle">
      <p>&lt; software engineer / web developer &gt;</p>
    </div>
  </div>
  <div class="hero__3d" [ngClass]="{'mobile': mobileOS()}"></div>
</div>
