import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-top-nav",
  templateUrl: "./top-nav.component.html",
  styleUrls: ["./top-nav.component.scss"],
})
export class TopNavComponent implements OnInit {
  public display = false;

  constructor() {}

  ngOnInit(): void {}

  openCV() {
    window.open("assets/HUGO MIRANDA - CV.pdf", "_blank", "fullscreen=yes");
    return false;
  }

  toggleMenu() {
    this.display = !this.display;
  }
}
