import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  public projects = [
    {
      id: 0,
      title: "sprint.com (2020)",
      url: "https://www.sprint.com",
      img: "sprint.webp",
    },
    {
      id: 1,
      title: "ooono.com (2022)",
      url: "https://ooono.com/",
      img: "ooono.webp",
    },
    {
      id: 2,
      title: "groupechenevert.com (2022)",
      url: "https://groupechenevert.com/fr",
      img: "gc.webp",
    },
    {
      id: 3,
      title: "trajectoires lapiscine.co (2022)",
      url: "https://trajectoires.lapiscine.co/",
      img: "la-piscine.webp",
    },
    {
      id: 4,
      title: "ownspace.com (2021)",
      url: "https://www.ownspace.com/en",
      img: "ownspace.webp",
    },
    {
      id: 5,
      title: "curvcarbon.com (2023)",
      url: "https://curvcarbon.com/",
      img: "curvcarbon.webp",
    },
    {
      id: 6,
      title: "onedelisle.com (2021)",
      url: "https://onedelisle.com/",
      img: "onedelisle.webp",
    },
    {
      id: 7,
      title: "theroost.com (2023)",
      url: "https://theroost.com/",
      img: "theroost.webp",
    },
    {
      id: 8,
      title: "forum-ol.com (2022)",
      url: "https://www.forum-ol.com/en",
      img: "forum.webp",
    },
    {
      id: 9,
      title: "wedding invite (2021)",
      url: "https://invite-e6514.web.app/",
      img: "invite.webp",
    },
    {
      id: 10,
      title: "team viewer (case)",
      url: "https://teamviewer-a638a.web.app/",
      img: "team-viewer.webp",
    },
    {
      id: 11,
      title: "tech events (case)",
      url: "https://canadatech-e715c.web.app/",
      img: "canadatech.webp",
    },
  ];

  constructor() {}

  ngOnInit(): void {
    console.log("ngOnInit");
    gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterContentInit(): void {
    console.log("ngAfterContentInit");
  }

  ngAfterViewInit(): void {
    console.log("ngAfterViewInit");
    const tlPortfolio = gsap.timeline({
      scrollTrigger: {
        trigger: ".project",
        pin: false,
        start: "top 50%",
        end: "bottom 50%",
      },
    });

    tlPortfolio.from(".project", { opacity: 0, y: 100, stagger: 0.1 });
  }
}
